<template>
  <div class="detail_content">
    <TopBar :type="2" :pageTitle="'文本课程详情'" />

    <div class="container">
      <div class="cont_box">
        <div class="left_img">
          <img :src="detailInfo.text_course.image" alt="" />
        </div>
        <div class="right_text">
          <p class="title">{{ detailInfo.text_course.title }}</p>
          <p class="text">{{ detailInfo.text_course.introduction }}</p>
          <div class="btn">
            <p @click="lookfor()">查看</p>
          </div>
        </div>
      </div>
      <div class="tab_list">
        <ul class="tab_nav">
            <li @click="tabIndex='1'" :class="tabIndex=='1'?'active':''">详情</li>
            <li @click="tabIndex='2'" :class="tabIndex=='2'?'active':''">目录</li>
        </ul>
        <div class="cont_show" :class="tabIndex=='1'?'activeBox':''">
            <p v-html="detailInfo.text_course.introduction"></p>
        </div>
        <div class="cont_show menu_list" :class="tabIndex=='2'?'activeBox':''">
            <ul>
                <li v-for="(item,index) in detailInfo.text_course_chapter" :key="item.id" @click="toPlay(item.id)">{{index+1}}.{{item.title}}</li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'

export default {
  name: "detail",
  components: {
    TopBar
  },
  data() {
    return {
      detailId: "",
      tabIndex:'1',
      detailInfo: {},
    };
  },
  mounted() {
    this.detailId = this.$route.query.id;
    if (this.detailId) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$axios
        .post(`/v1/textCourse/detail`, { id: this.detailId })
        .then((res) => {
          if (res.code == 0) {
            this.detailInfo = res.data;
          }
        });
    },
    lookfor(){
         this.$router.push('/textCourse/playText?id='+this.detailInfo.text_course_chapter[0].id+'&text_course_id='+this.detailId)
    },
    toPlay(id){
        this.$router.push('/textCourse/playText?id='+id+'&text_course_id='+this.detailId)
    }
  },
};
</script>

<style lang="scss" scoped>
.detail_content {
  background: #f6f6f6;
  // padding: 30px 0;
  .container {
    width: 1200px;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto;
    padding: 30px;
    .cont_box {
      display: flex;
      .left_img {
        width: 240px;
        height: 320px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right_text {
        margin-left: 20px;
        position: relative;
        p.title {
          font-size: 20px;
          font-weight: bold;
        }
        p.text {
          margin-top: 10px;
          font-size: 15px;
        }
        div.btn {
            position: absolute;
            left: 20px;
            bottom: 20px;
          p {
            width: 120px;
            background: #254ed4;
            border-radius: 4px;
            padding: 20px 0;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            line-height: 16px;
            box-sizing: border-box;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
    .tab_list{
        margin-top: 30px;
        background: #fff;
        .tab_nav{
            display: flex;
            li{
                font-size: 20px;
                margin-right: 40px;
                padding-bottom: 10px;
                border-bottom: 2px solid #fff;
                cursor: pointer;
            }
            li.active{
                border-bottom: 2px solid #3ca7fa;
                font-weight: bold;
            }
        }
        .cont_show{
            margin-top: 30px;
        }

    }
    .cont_show{
        display: none;
        padding-bottom: 20px;
        p{
            line-height: 20px;
            font-size: 18px;
        }
    }
    .menu_list{
        ul{
            li{
                line-height: 60px;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
    .activeBox{
        display: block;
    }
  }
}
</style>